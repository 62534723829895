import React, { useState, useMemo, useRef } from 'react';
import request from './../../../services/AxiosInstance';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment-timezone';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import 'ag-grid-enterprise';
import { GrPowerReset } from 'react-icons/gr';
import { toast } from 'react-toastify';
import { Loader } from '../../components/bootstrap/Loader';

ModuleRegistry.registerModules([ServerSideRowModelModule]);

let TransactionFilterObj = {
    fromdate: "",
    searchObject: "",
    pageNo: 0,
    pageSize: 20,
    sortOrder: "desc",
    sortColumn: "created_on",
    group_ids: [],
    tableName: "",
    todate: "",
    group_id: null
};

const AuditTrail = () => {
    const [auditTrailData, setAuditTrailData] = useState([]);
    const gridRef = useRef();
    const groupRef = useRef([]);
    const [rowPerPage] = useState(5);
    const [loader, setLoader] = useState(false)
    const [group, setGroup] = useState([]);
    TransactionFilterObj.group_id = sessionStorage.getItem("groupID");
    request('get', `groups/search`)
        .then(res => {
            if (res && res.data && res.data.length > 0) {
                const groupResult = res;
                groupRef.current = groupResult.data;
                setGroup(groupResult.data);
            }
        })
        .catch(e => console.error(e))

    const paginationPageSizeSelector = useMemo(() => [5, 10, 20], []);

    const onPaginationChanged = (params) => {
        const newPageSize = params.api.paginationGetPageSize();
        if (rowPerPage !== newPageSize) {
            TransactionFilterObj.pageSize = newPageSize;
        }
    };
    const gridOptions = {
        rowModelType: 'serverSide',
        gridBodyCls: 'ag-layout-auto-height',
    };

    const onGridReady = (params) => {
        const datasource = getServerSideDatasource();
        params.api.setServerSideDatasource(datasource);
        params.api.sizeColumnsToFit();
    };

    const getServerSideDatasource = () => {
        return {
            getRows(params) {
                TransactionFilterObj.pageNo = params.api.paginationGetCurrentPage() + 1;
                const filterObject = params.request && params.request.filterModel
                const filterColumns = Object.keys(filterObject)


                if (filterObject) {
                    if (filterColumns.length === 0) {
                        TransactionFilterObj.searchObject = ''
                    } else {
                        TransactionFilterObj.searchObject = filterObject
                    }
                }

                //to set group id
                if (filterObject.group) {
                    const filterValues = filterObject.group.values;
                    TransactionFilterObj.group_ids = groupRef.current.filter(val => {
                        return filterValues.includes(val.name)
                    }).map(val => val.id)
                } else {
                    TransactionFilterObj.group_ids = []
                }

                const isGroupNotSelected = Boolean(filterObject.box && filterObject.group.values.length === 0)

                if (isGroupNotSelected === true) {
                    // store or box is not selected, so empty data
                    params.success({ rowData: [], rowCount: 0 });
                } else { // store or box is selected
                    apiCall()
                }

                function apiCall() {
                    request('post', 'apis/i-ecom/handleorders/order/audit_report', TransactionFilterObj)
                        .then(response => {
                            if (response && response.results) {
                                if (response.results.length > 0) {
                                    params.success({ rowData: response.results, rowCount: response.total });
                                    setAuditTrailData(response.results);
                                } else {
                                    params.success({ rowData: [], rowCount: 0 });
                                }
                            }
                        })
                        .catch(error => {
                            console.error(error);
                            params.fail();
                        })
                }
            }
        };
    }

    const DateCellFormatter = (params) => {
        if (params && params.data && params.data.created_on) {
            const strTimezone = sessionStorage.getItem('timeZone')
            const sessionTimeZone = strTimezone.split(') ')[1];
            const timeZone = ![undefined, 'undefined', null, 'null', ''] ? sessionTimeZone : 'America/Los_Angeles';
            const date = moment(new Date(params.data.created_on));
            return date.tz(timeZone).format('MM/DD/YYYY hh:mm:ss A');
        } else {
            return ''
        }
    }

    const TableHeader = [
        {
            headerName: "Date/Time", field: "created_on",
            cellRenderer: DateCellFormatter,
            filter: 'agDateColumnFilter',
            width: 200,
            filterParams: {
                filterOptions: [
                    {
                        displayKey: 'fromdate',
                        displayName: "FromDate",
                        predicate: (_, cellValue) => cellValue
                    },
                    {
                        displayKey: 'todate',
                        displayName: "ToDate",
                        predicate: (_, cellValue) => cellValue
                    },
                ],
                maxNumConditions: 2,
            },
        },
        { headerName: 'First Name', field: "first_name" },
        { headerName: "Last Name", field: "last_name" },
        { headerName: "Stock Code", field: "stock_code" },
        { headerName: "P/U Code", field: "pucode" },
        { headerName: "Store", field: "store_name" },
        { headerName: "Location", field: "location_name" },
        { headerName: "Action", field: "action_name" },
        { headerName: "Description", field: "description" },
        { headerName: "UserName", field: "username" },
        {
            headerName: "Group", field: "group", filter: true,
            filterParams: {
                values: group.map(g => {
                    return g.name
                })
            }
        }
    ];

    const handleFilterReset = () => {
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.setFilterModel(null);
            gridRef.current.api.onFilterChanged();
        }
    }

    const fetchData = async () => {
        const totalRows = gridRef.current.api.paginationGetRowCount();
        TransactionFilterObj.pageSize = totalRows
        const response = await request('post', 'apis/i-ecom/handleorders/order/audit_report', TransactionFilterObj);
        if (!response || !response.results) {
            toast.error('Error while fetching data for CSV')
            setLoader(false)
            return []
        }
        return response.results;
    };

    const onExportAllData = async () => {
        setLoader(true)
        const allData = await fetchData();

        if (allData.length > 0) {
            const columnDefs = gridRef.current.api.getColumnDefs();

            const csvData = allData.map(row => {
                let rowData = {};
                columnDefs.forEach(colDef => {
                    const field = colDef.field;
                    if (field) {
                        rowData[field] = row[field] ?? '';
                    }
                });
                return rowData;
            });

            let csvContent = 'data:text/csv;charset=utf-8,';
            const headers = columnDefs.map(colDef => colDef.headerName).join(',');
            csvContent += `${headers}\n`;

            csvData.forEach(row => {
                const rowString = columnDefs.map(colDef => {
                    const field = colDef.field;
                    if (field) {
                        return `"${row[field]}"`;
                    }
                    return '';
                }).join(',');
                csvContent += `${rowString}\n`;
            });

            const encodedUri = encodeURI(csvContent);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', 'AdutiTrail_data.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoader(false)
        } else {
            setLoader(false)
            toast.error("Error while creating CSV")
        }
    };

    const getContextMenuItems = () => {
        return [
            'copy',
            'copyWithHeaders',
            {
                name: 'Export All Data',
                action: onExportAllData,
            },
        ];
    };

    return (
        <div>
            {loader ? <div className="loader">
                <Loader />
            </div> : ""}
            <div className='text-end fs-26 mb-3'>
                <span className='mx-4' onClick={() => handleFilterReset()}>
                    <GrPowerReset size={26} color='gray' />
                </span>
            </div>
            <div id="myGrid" className={"ag-theme-quartz"} style={{ boxSizing: "border-box", width: "100%" }}>
                <AgGridReact
                    ref={gridRef}
                    sideBar={false}
                    suppressMenuHide={true}
                    columnDefs={TableHeader}
                    rowData={auditTrailData}
                    defaultColDef={{
                        minWidth: 100,
                        floatingFilter: true,
                        filter: 'agTextColumnFilter',
                        filterParams: {
                            filterOptions: [{
                                displayKey: 'searchtext',
                                displayName: 'Search Text',
                                predicate: (cellValue) => cellValue
                            }],
                            maxNumConditions: 1,
                        },
                        resizable: true,
                        sortable: false,
                        menuTabs: ['generalMenuTab', 'columnsMenuTab'],
                    }}
                    pagination={true}
                    paginationPageSize={20}
                    paginationPageSizeSelector={paginationPageSizeSelector}
                    cacheBlockSize={20}
                    maxBlocksInCache={0}
                    onGridReady={onGridReady}
                    gridOptions={gridOptions}
                    onPaginationChanged={onPaginationChanged}
                    getContextMenuItems={getContextMenuItems}
                />
            </div>
        </div>
    )
}

export default AuditTrail